import { Logos } from '.';

export const logos: Logos = {
    press: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/1.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/1.png`,
            alt: 'Business Insider Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/2.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/2.png`,
            alt: 'Business Live Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/3.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/3.png`,
            alt: 'Manchester Evening News Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/4.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/4.png`,
            alt: 'Daily Record Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/5.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/5.png`,
            alt: 'The National Logo',
        },
    ],
    educators: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/manchester-uni.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/manchester-uni.png`,
            alt: 'University of Manchester Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/nhs.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/nhs.png`,
            alt: 'NHS Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/mind.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/mind.png`,
            alt: 'Mind Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/historical-association.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/historical-association.png`,
            alt: 'Historical Association Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/ifs.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/ifs.png`,
            alt: 'Institute of Fiscal Studies Logo',
        },
    ],
    clients: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/acrisure.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/acrisure.png`,
            alt: 'Acrisure Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/noble-foods.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/noble-foods.png`,
            alt: 'Noble Foods Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/nhs.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/nhs.png`,
            alt: 'NHS Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.png`,
            alt: 'Active Lancashire Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/happy-egg-co.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/happy-egg-co.png`,
            alt: 'Happy Eggs Logo',
        },
    ],
};
